/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { memo, useEffect, useRef, useState } from "react";
import { jsx } from "theme-ui";

const Water: React.FC<{
  speed?: number;
  background?: string | null;
  children: React.ReactNode;
}> = ({ speed = 2, background, children }) => {
  const [refReady, setRefReady] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let destroy: () => void | undefined;

    if (refReady && ref.current && canvas.current && background) {
      import("./helpers").then(({ createWater }) => {
        if (ref.current && canvas.current) {
          destroy = createWater(ref.current, canvas.current, background, speed);
        }
      });
    }

    return (): void => {
      if (destroy) {
        destroy();
      }
    };
  }, [refReady]);

  return (
    <div
      ref={(domRef: HTMLDivElement): void => {
        // @ts-ignore ts(2540)
        ref.current = domRef;
      }}
      sx={{ height: "100%", width: "100%" }}
    >
      <div sx={{ position: "absolute", height: "100%", width: "100%" }}>
        {children}
      </div>
      <canvas
        ref={(domRef: HTMLCanvasElement): void => {
          // @ts-ignore ts(2540)
          canvas.current = domRef;
          setRefReady(true);
        }}
        sx={{ position: "absolute" }}
      />
    </div>
  );
};

export default memo(Water);
