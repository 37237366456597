/** @jsx jsx */
import Img, { FluidObject } from "gatsby-image";
import { memo, useState } from "react";
import Particles, { MoveDirection, OutMode } from "react-particles-js";
import { jsx } from "theme-ui";

import Container from "../Container";
import css from "./style.module.css";

interface Picture {
  base64?: string | null;
  aspectRatio?: number | null;
  src?: string | null;
  srcSet?: string | null;
  sizes?: string | null;
}

const getRGB = (rgb: string): string[] =>
  rgb
    .substring(4, rgb.length - 1)
    .replace(/ /g, "")
    .split(",");

const Header: React.FC<{
  animal?: Picture | null;
  animalName?: string | null;
  background?: Picture | null;
  children?: React.ReactNode;
  gradientStart?: string | null;
  gradientStop?: string | null;
  name?: Picture | null;
  noFx?: boolean;
}> = ({
  animal,
  animalName,
  background,
  children,
  gradientStart,
  gradientStop,
  name,
  noFx = false,
}) => {
  const [showAnimal, setShowAnimal] = useState<boolean>(false);
  const [showName, setShowName] = useState<boolean>(false);
  const [startR, startG, startB] = getRGB(gradientStart || "rgb(0,0,0)");
  const [stopR, stopG, stopB] = getRGB(gradientStop || "rgb(0,0,0)");

  return (
    <header className={css.header}>
      {background && (
        <div className={css.background}>
          <Img
            fluid={background as FluidObject}
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover", objectPosition: "top center" }}
          />
        </div>
      )}
      {!noFx && gradientStart && gradientStop && (
        <div
          className={css.gradient}
          sx={{
            backgroundImage: `linear-gradient(
          45deg,
          rgba(${startR}, ${startG}, ${startB}, 0),
          rgba(${stopR}, ${stopG}, ${stopB}, 0.5)
        )`,
          }}
        />
      )}
      {name && (
        <div
          className={showName && showAnimal ? css.nameAnimate : css.name}
          sx={{ opacity: showName && showAnimal ? 1 : 0 }}
        >
          <Img
            fadeIn={false}
            fluid={name as FluidObject}
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            onLoad={(): void => {
              setShowName(true);
            }}
          />
        </div>
      )}
      <Particles
        className={css.layer}
        params={{
          particles: {
            color: {
              value: "#fff",
            },
            size: {
              value: 3,
              random: true,
            },
            opacity: {
              value: 0.1,
              random: false,
              anim: {
                enable: false,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              out_mode: OutMode.out,
              direction: MoveDirection.right,
            },
          },
        }}
      />
      {animal && (
        <div
          className={showName && showAnimal ? css.animalAnimate : css.animal}
          sx={{ opacity: showName && showAnimal ? 1 : 0 }}
        >
          <Img
            alt={animalName || ""}
            fadeIn={false}
            fluid={animal as FluidObject}
            style={{
              height: "100%",
              width: "100%",
            }}
            imgStyle={{
              objectFit: "contain",
            }}
            onLoad={(): void => {
              setShowAnimal(true);
            }}
          />
        </div>
      )}
      {children && (
        <div sx={{ position: "relative" }}>
          <Container>{children}</Container>
        </div>
      )}
    </header>
  );
};

export default memo(Header);
