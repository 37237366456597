/** @jsx jsx */
import Img, { FluidObject } from "gatsby-image";
import { memo, useState } from "react";
import Particles, { MoveDirection, OutMode } from "react-particles-js";
import { jsx } from "theme-ui";

import Container from "../Container";
import css from "./style.module.css";
import Water from "./Water";

interface Picture {
  base64?: string | null;
  aspectRatio?: number | null;
  src?: string | null;
  srcSet?: string | null;
  sizes?: string | null;
}

const Header: React.FC<{
  animal?: Picture | null;
  animalName?: string | null;
  background?: Picture | null;
  children?: React.ReactNode;
  name?: Picture | null;
  noFx?: boolean;
}> = ({ animal, animalName, background, children, name, noFx = false }) => {
  const [showAnimal, setShowAnimal] = useState<boolean>(false);
  const [showName, setShowName] = useState<boolean>(false);

  return (
    <header className={css.header}>
      {background?.src && (
        <div className={css.layer}>
          {noFx ? (
            <Img
              fluid={background as FluidObject}
              style={{ height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "cover", objectPosition: "top center" }}
            />
          ) : (
            <Water background={background.src}>
              <Img
                fluid={background as FluidObject}
                style={{ height: "100%", width: "100%" }}
                imgStyle={{ objectFit: "cover", objectPosition: "top center" }}
              />
            </Water>
          )}
        </div>
      )}
      {name && (
        <div
          className={showName && showAnimal ? css.nameAnimate : css.name}
          sx={{ opacity: showName && showAnimal ? 1 : 0 }}
        >
          <Img
            fadeIn={false}
            fluid={name as FluidObject}
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            onLoad={(): void => {
              setShowName(true);
            }}
          />
        </div>
      )}
      <Particles
        className={css.layer}
        params={{
          particles: {
            color: {
              value: "#fff",
            },
            size: {
              value: 3,
              random: true,
            },
            opacity: {
              value: 0.1,
              random: false,
              anim: {
                enable: false,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              straight: true,
              random: true,
              out_mode: OutMode.out,
              direction: MoveDirection.top,
            },
          },
        }}
      />
      {animal && (
        <div
          className={showName && showAnimal ? css.animalAnimate : css.animal}
          sx={{ opacity: showName && showAnimal ? 1 : 0 }}
        >
          <Img
            alt={animalName || ""}
            fadeIn={false}
            fluid={animal as FluidObject}
            style={{
              height: "100%",
              width: "100%",
            }}
            imgStyle={{
              objectFit: "contain",
            }}
            onLoad={(): void => {
              setShowAnimal(true);
            }}
          />
        </div>
      )}
      {children && (
        <div sx={{ position: "relative" }}>
          <Container>{children}</Container>
        </div>
      )}
    </header>
  );
};

export default memo(Header);
